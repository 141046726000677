import React from "react";
import Header from "../common/header/Header";
import FooterEugene from "../common/footer/FooterEugene";
import {Image} from "react-bootstrap";

const EugeneNetworkPage = () => {
    return (
        <>
            <Header/>

            <section className="work-perfomance-one-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="work-perfomance-one__content">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Politique de confidentialité</h2>
                                    <h2 className={"mt-5"}>Collecte d'informations</h2>
                                    <p>Notre application ne collecte aucune information personnelle identifiable. Cependant, elle peut collecter anonymement des informations de diagnostic telles que les crashs et les rapports ANR (Application Not Responding) afin d'améliorer la stabilité et les performances de l'application.</p>

                                    <h2 className={"mt-5"}>Utilisation des informations</h2>
                                    <p>Les informations de diagnostic collectées sont utilisées uniquement à des fins de dépannage, d'amélioration des performances et de qualité de l'application. Elles ne sont en aucun cas associées à des utilisateurs individuels.</p>

                                    <h2 className={"mt-5"}>Partage d'informations</h2>
                                    <p>Nous ne partageons pas vos informations avec des tiers, sauf si cela est nécessaire pour se conformer à la loi ou pour protéger nos droits légaux.</p>

                                    <h2 className={"mt-5"}>Sécurité</h2>
                                    <p>Nous prenons des mesures raisonnables pour protéger les informations collectées contre toute utilisation non autorisée, la perte, la divulgation ou la modification.</p>

                                    <h2 className={"mt-5"}>Liens vers des sites tiers</h2>
                                    <p>Notre application peut contenir des liens vers des sites tiers. Veuillez noter que nous ne sommes pas responsables des pratiques de confidentialité de ces sites. Nous vous encourageons à lire les politiques de confidentialité de ces sites avant de fournir des informations personnelles.</p>

                                    <h2 className={"mt-5"}>Changements apportés à cette politique</h2>
                                    <p>Nous pouvons mettre à jour cette politique de confidentialité de temps en temps pour refléter les changements dans nos pratiques de collecte et de gestion des informations. La date de la dernière mise à jour sera indiquée en haut de cette politique.</p>

                                    <h2 className={"mt-5"}>Contactez-nous</h2>
                                    <p>Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, veuillez nous contacter à l'adresse suivante : developeur-@-les-eugene.fr.</p>
                                </div>
                                <div>
                                    <div className="text-box">
                                        <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/vagues.png"}
                                               className={"image-fit"}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </section>

            <section className="team-one-sec">
                <div className="shape1 float-bob-y">
                    <Image src={process.env.PUBLIC_URL + "/assets/images/shapes/team-v1-shape1.png"} alt=""/></div>
                <div className="container">
                    <div className="sec-title text-center">
                        <div className="sec-title__tagline">
                            <span className="left"></span>
                            <h6>Notre équipe</h6> <span className="right"></span>
                        </div>
                        <h2 className="sec-title__title">Des membres expérimentés</h2>
                        <p>
                            Notre équipe au siège est composée de 12 personnes, qui, fort de leur expérience ont acquis
                            les techniques de désinfection les plus optimales et qui transmettent ce savoir-faire
                            spécifiques à tous nos partenaires.
                        </p>
                    </div>
                    <div className="row">
                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.1s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/leseugene/BMacle.png"} alt=""/>
                                    <div className="content-box">
                                        <h2>
                                            Béatrice Macle
                                        </h2>
                                        <span>Administration</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}

                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.3s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/DESERTOT.png"}
                                           alt=""/>
                                    <div className="content-box">
                                        <h2>
                                            François-Xavier Desertot
                                        </h2>
                                        <span>Dirigeant & Fondateur</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}

                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.5s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/CBrauen.png"}
                                           alt=""/>
                                    <div className="content-box">
                                        <h2>
                                            Clarisse Brauen
                                        </h2>
                                        <span>Marketing & Communication</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}
                    </div>
                    <div className="row d-flex justify-content-evenly">
                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.5s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/OAhadi.png"}
                                           alt=""/>
                                    <div className="content-box">
                                        <h2>
                                            Oualid Ahadi
                                        </h2>
                                        <span>Responsable opérationnel et qualité <br /> Lavage Colonnes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}

                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.5s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/AAhadi.png"}
                                           alt=""/>
                                    <div className="content-box">
                                        <h2>
                                           Aziz Ahadi
                                        </h2>
                                        <span>Responsable opérationnel et qualité <br />Lavage Bacs</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}
                    </div>
                </div>
            </section>

            <section className="work-perfomance-one-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="work-perfomance-one__content">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Un parc de laveuse</h2>
                                    <p className="sec-title__text">
                                        8 laveuses de bacs et 2 laveuses de colonnes composent le parc des laveuses du
                                        réseau Les Eugène.
                                    </p>
                                    <p className="mt-2">
                                        6 autres laveuses vont bientôt compléter le parc pour répondre à toutes vos
                                        demandes.
                                    </p>
                                </div>
                                <div>
                                    <div className="text-box">
                                        <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/vagues.png"}
                                               className={"image-fit"}/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-5">
                            <div className="work-perfomance-one__img">
                                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/laveuses.png"}
                                       className="image-fit"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                       alt="logo poubelle" className="poubelle-filigrane"/>
            </section>

            {/*<BlogOne/>*/}
            <FooterEugene/>
        </>
    )
}

export default EugeneNetworkPage;
