import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Image} from "react-bootstrap";
import Constants from "../../constants";

const Network = () => {
    return (
        <>
            <div className="about-three">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <p className="network-title">Les clients Les Eugène</p>
                            <div className="about-three__img">
                                <div className="inner">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/network-map.png"}
                                           alt="carte du réseau"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="about-three__content">
                                <div className="sec-title">
                                    <div className="sec-title__tagline">
                                        <h6> À Propos </h6> <span className="right"></span>
                                    </div>
                                </div>

                                <div className="about-three__content-inner">
                                    <div className="text1">
                                        <p>
                                            Dû à la forte croissance de la demande, la législation et l'obligation de
                                            toutes les collectivités d'avoir une solution de tri à la source des biodéchets
                                            en 2024

                                        </p>
                                    </div>
                                    <div className="text2">
                                        <h4>Le réseau Les Eugène répond à votre demande en lavant 9000 colonnes,
                                            8500 abri bacs biodéchets et 84 000 bac roulants dans toute la France.</h4>
                                    </div>

                                    <div className="row">

                                    </div>

                                    <div className="about-three__content-btn">
                                        <NavLink className="thm-btn"
                                                 data-text="En savoir plus" to={`${Constants.URL_RESEAU}`}>
                                            En savoir plus</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                       alt="logo poubelle" className="network-logo-poubelle"/>
            </div>
        </>
    )

}

export default Network;
