import React from 'react';
import {Link} from 'react-router-dom';
import {Image} from "react-bootstrap";
import CONSTANTS from "../../constants";

export default class NosServices extends React.Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <section className="services-three">
                    <div className="shape1"><img src={publicUrl + "assets/images/shapes/services-v3-shape1.png"}
                                                 alt="#"/></div>
                    <div className="shape2 rotate-me"><img
                        src={publicUrl + "assets/images/update1.0/services-v3-shape2.png"} alt="#"/></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="services-three__top">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <h6>Les Eugène </h6> <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title">Notre métier</h2>
                                    </div>
                                    <div className="text-box">
                                        <p>Les Eugène sont spécialisés dans le lavage de conteneurs à déchets et
                                            offrent <br/>
                                            des services complets adaptés aux besoins des clients. </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row services-container">
                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <div className="services-three__single active">
                                    <div className="services-three__single__bg"
                                         style={{backgroundImage: 'url(' + publicUrl + 'assets/images/leseugene/laveuse4.png)'}}></div>
                                    <div className="services-three__single-icon">
                                        <Image
                                            src={process.env.PUBLIC_URL + "/assets/images/leseugene/recycle-bin.png"}/>
                                    </div>
                                    <h3><Link to={CONSTANTS.URL_BAC}>Lavage des bacs roulants et abris-bacs</Link></h3>
                                    <p></p>
                                    <div className="btn-box">
                                        <Link to={CONSTANTS.URL_BAC}><span
                                            className="icon-right-arrow"></span></Link>
                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <div className="services-three__single">
                                    <div className="services-three__single__bg"
                                         style={{backgroundImage: 'url(' + publicUrl + 'assets/images/leseugene/laveuse.png)'}}></div>
                                    <div className="services-three__single-icon">
                                        <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/dechets.png"}/>
                                    </div>
                                    <h3><Link to={CONSTANTS.URL_COLONNE}>Lavage de tous types de colonnes</Link></h3>
                                    <p></p>
                                    <div className="btn-box">
                                        <Link to={CONSTANTS.URL_COLONNE}><span
                                            className="icon-right-arrow"></span></Link>
                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}

                            {/* End Services One Single */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
