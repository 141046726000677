import React from 'react';
import {Image} from "react-bootstrap";


const Hero = () => {

    return (
        <>
            <section className="page hero-container">
                <div className="container text-center">
                    <Image src={process.env.PUBLIC_URL+"/assets/images/leseugene/logo_eugene.png"} className="hero-logo"/>
                    <p className="hero-text italic">
                        Les spécialistes de la désinfection de <br />tous les types de contenants à déchets
                    </p>
                </div>
            </section>
        </>
    )
}

export default Hero;
