import React from 'react';
import YouTube from "react-youtube";


const MotionDesign = () => {
    let publicUrl = process.env.PUBLIC_URL + '/';
    const videoId = "rBBP-nMfSLg";

    const videoOpts = {
        width: "100%",
        height: '600px'
    }

    return (
        <>
            <section className="brand-one-sec">
                <div className="brand-one__bg"
                     style={{backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/brand-v1-bg.png)'}}></div>
                <div className="container" itemType="http://schema.org/VideoObject" itemScope>
                    {/*    <video width="100%" height="100%" controls>*/}
                    {/*        <source src={publicUrl + "assets/videos/leseugene-motion.mp4"} type="video/mp4"/>*/}
                    {/*        Votre navigateur ne supporte pas la balise vidéo.*/}
                    {/*    </video>*/}
                    <YouTube videoId={videoId}
                             title={"Les Eugène: le réseau national des spécialistes du lavage de contenants"}
                             opts={videoOpts}/>
                </div>
                {/*<meta itemProp="name" content="Les Eugène"/>*/}
                {/*<meta itemProp="description" content="Présentation du métier Les Eugene"/>*/}
                {/*<meta itemProp="thumbnailUrl" content={publicUrl + "assets/images/lesEugene/video-thumbnail.png"}/>*/}
                {/*<meta itemProp="uploadDate" content="09/05/2023"/>*/}

            </section>
        </>
    )
}

export default MotionDesign;
