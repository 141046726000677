import React from "react";
import Header from "../common/header/Header";
import FooterEugene from "../common/footer/FooterEugene";
import {Image} from "react-bootstrap";

const EugeneBacPage = () => {
    return (
        <>
            <Header/>

            <section className="mt-5 mb-5">
                <div className="container">
                    <div className="page-header__title">
                        <h1>Une laveuse unique en Europe</h1>
                    </div>
                    <div className="page-header__subtitle italic">
                        <h4>Conçue par des laveurs pour des laveurs</h4>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <p>
                                Notre laveuse est brevetée (n° FR2205922) depuis 4 ans et répond aux besoins
                                écologiques, technologiques et novateurs.
                                Nous réalisons le nettoyage et la désinfection intérieur et extérieur de l'ensemble des
                                bacs roulants et des abris-bacs biodéchets du marché, dans tous les contextes.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 d-flex justify-content-center">
                        <div className="col-xs-12 col-lg-3 text-center d-flex flex-column justify-content-between">
                            <Image src={process.env.PUBLIC_URL+"/assets/images/leseugene/bac.jpeg"} className="picto-bac" alt="Image de bac roulant" />
                            <p className="mt-5">80 - 120 litres</p>
                        </div>
                        <div className="col-xs-12 col-lg-3 text-center d-flex flex-column justify-content-between">
                            <Image src={process.env.PUBLIC_URL+"/assets/images/leseugene/bac-large.jpeg"} className="picto-bac" alt="Image de bac roulant" />
                            <p className="mt-5">500 - 1000 litres</p>
                        </div>
                        <div className="col-xs-12 col-lg-3 text-center d-flex flex-column justify-content-between">
                            <Image src={process.env.PUBLIC_URL+"/assets/images/leseugene/abribac.jpeg"} className="picto-bac" alt="Image de bac roulant" />
                            <p className="mt-5">Abris bac biodéchets</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-perfomance-one-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="work-perfomance-one__content">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Un lavage efficace</h2>
                                    <p className="sec-title__text">
                                        Le petit gabarit du véhicule permet de laver tous les bacs en se fondant
                                        dans la masse
                                        urbaine.
                                        <br/>
                                        Il peut se garer partout sans gêner la circulation, aller dans les pakings,
                                        les zones
                                        commerciales et même les souterrain.
                                    </p>
                                </div>
                                <div>
                                    <div className="text-box">
                                        <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/vagues.png"}
                                               className={"image-fit"}/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-5">
                            <div className="work-perfomance-one__img">
                                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/laveuse.png"}
                                       className="image-fit"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                       alt="logo poubelle" className="poubelle-filigrane"/>
            </section>

            <section className="about-one-sec--about">
                <div className="container">
                    <div className="row">

                        <div className="col-xl-5">
                            <div className="work-perfomance-one__img">
                                {/*<div className="about-one__img-box-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/about/about-v1-img1.jpg)'}}></div>*/}
                                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/lavage-de-qualite.png"}
                                       className="image-fit"/>
                            </div>
                        </div>

                        <div className="col-xl-7">
                            <div className="about-one__content">
                                <div className="sec-title">
                                    <div className="sec-title__tagline">

                                    </div>
                                    <h2 className="sec-title__title">Un lavage de qualité</h2>
                                    <p className="sec-title__text">
                                        Nous l'avons l'intérieur et l'extérieur des bacs et des abris bacs grâce à
                                        un robot de lavage exclusif et un jet haute pression.
                                        <br/>
                                    </p>
                                </div>

                                <div className="about-one__conten-bottom">
                                    <p>
                                        Nos laveurs accrédités assurent les prestations et la maitrise du
                                        savoir-faire Les Eugène.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="project-three">
                <div className="container">
                    <div className="sec-title text-center">
                        <h2 className="sec-title__title">Un lavage écologique</h2>
                        <p className="sec-title__text">
                            La machinerie de la laveuse 100% éléctrique ainsi que le système de
                            filtration et de recyclage de l'eau réduisent considérablement l'empreinte
                            carbone grâce à un très faible taux d'émission de CO2
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="work-perfomance-one__img">
                                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/laveuse3.png"}
                                       className="image-fit"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-one-sec--about">
                <div className="container">
                    <div className="sec-title text-center">
                        <h2 className="sec-title__title">Une application et un extranet pour la gestion</h2>
                        <p className="sec-title__text">
                            Nous avons développé une application reliée à l'extranet, afin que toutes les parties
                            puissent suivre (client), gérer (exploitant) et exécuter les prestations de lavage
                            (opérateur).
                        </p>
                    </div>
                    <div className="row">

                        <div className="col-xl-12">
                            <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/schema.png"}
                                   className="image-fit schema-img"/>
                        </div>
                    </div>
                </div>

                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                       alt="logo poubelle" className="poubelle-filigrane"/>
            </section>
            {/*<BlogOne/>*/}
            <FooterEugene/>
        </>
    )
}

export default EugeneBacPage;
