import React from "react";
import BrandOne from "../components/brand/BrandOne";
import Header from "../common/header/Header";
import Hero from "../components/lesEugene/Hero";
import MotionDesign from "../components/lesEugene/MotionDesign";
import Network from "../components/lesEugene/Network";
import NosServices from "../components/lesEugene/NosServices";
import FooterEugene from "../common/footer/FooterEugene";
import RevuePresse from "../components/lesEugene/RevuePresse";

const EugeneHome = () => {
    return (
        <>
            <Header/>
            <Hero/>
            <MotionDesign/>
            <Network/>
            <NosServices/>
            <RevuePresse/>

            <BrandOne/>
            {/*<BlogOne/>*/}
            <FooterEugene/>
        </>
    )
}

export default EugeneHome;
