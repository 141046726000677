import React from "react";
import Header from "../common/header/Header";
import FooterEugene from "../common/footer/FooterEugene";
import {Image} from "react-bootstrap";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

const EugeneColonnePage = () => {

    const FIRST_IMAGE = {
        imageUrl: process.env.PUBLIC_URL + "/assets/images/leseugene/before.JPG"
    };
    const SECOND_IMAGE = {
        imageUrl: process.env.PUBLIC_URL + "/assets/images/leseugene/after.JPG"
    };

    const delimiterIconStyle = {
        width: '200px',
        height: '100px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: 'none',
        backgroundImage: 'url('+process.env.PUBLIC_URL + "/assets/images/leseugene/mini-laveuse.png"+')',
        transform:'scaleX(-1)',
        backgroundColor:'transparent'
    }

    return (
        <>
            <Header/>

            <section className="mt-5 mb-5">
                <div className="container">
                    <div className="page-header__title">
                        <h1>Une laveuse écologique pour les colonnes</h1>
                    </div>
                    <div className="page-header__subtitle italic">

                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <p>
                                Notre laveuse équipée d'une grue de levage pour soulever tous les types de système
                                (champignon, simple crochet) nous permet de réaliser la désinfection intérieur et
                                extérieur à haute pression de tous les types de colonnes : enterrées, semi-enterrées et
                                aérienne.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 d-flex justify-content-center">
                        <div className="col-xs-12 col-lg-3 text-center d-flex flex-column justify-content-between">
                            <Image src={process.env.PUBLIC_URL+"/assets/images/leseugene/ca.jpeg"} className="picto-bac" alt="Image de colonne aérienne" />
                            <p className="mt-5">Colonne aérienne</p>
                        </div>
                        <div className="col-xs-12 col-lg-3 text-center d-flex flex-column justify-content-between">
                            <Image src={process.env.PUBLIC_URL+"/assets/images/leseugene/cse.jpeg"} className="picto-bac" alt="Image de colonne semi enterrée" />
                            <p className="mt-5">Colonne semi enterrée</p>
                        </div>
                        <div className="col-xs-12 col-lg-3 text-center d-flex flex-column justify-content-between">
                            <Image src={process.env.PUBLIC_URL+"/assets/images/leseugene/ce.jpeg"} className="picto-bac" alt="Image de colonne enterrée" />
                            <p className="mt-5">Colonne enterrée</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-perfomance-one-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="work-perfomance-one__content">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Un lavage efficace</h2>
                                    <p className="sec-title__text">
                                        Nos robots de lavage utilisent de l'eau chaude et à haute pression qui
                                        garantissent l'élimination de tous les résidus légers et solides attachés aux
                                        parois, ainsi qu'une destruction maximale des bactéries.
                                    </p>
                                </div>
                                <div>
                                    <div className="text-box">
                                        <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/vagues.png"}
                                               className={"image-fit"}/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-5">
                            <div className="work-perfomance-one__img">
                                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/laveuse4.png"}
                                       className="image-fit"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                       alt="logo poubelle" className="poubelle-filigrane"/>
            </section>

            <section className="about-one-sec--about">
                <div className="container">
                    <div className="sec-title">
                        <h2 className="sec-title__title text-center">Un lavage de qualité</h2>
                        <p className="sec-title__text">
                            Notre laveuse utilise des produits écologiques de désinfection et de désodorisation.
                        </p>
                        <p className="sec-title__text">
                            Son unité de traitement des eaux permet de recycler l'eau pour bénéficier d'une économie 8
                            fois supérieure au système au système traditionnel.
                        </p>
                        <p className="sec-title__text">
                            Nos laveurs accrédités assurent les prestations et la maitrise du savoir-faire Les
                            Eugène.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 d-flex justify-content-center">
                            <div className="before-after-container">

                                <p className="text-center italic">Bougez la laveuse pour voir avant / après</p>
                                <ReactBeforeSliderComponent
                                    firstImage={FIRST_IMAGE}
                                    secondImage={SECOND_IMAGE}
                                    currentPercentPosition={33}
                                    delimiterIconStyles={delimiterIconStyle}
                                />

                                {/*<Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/ecofriendly.png"}*/}
                                {/*       className="image-fit"/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="project-three">
                <div className="container">
                    <div className="sec-title text-center">
                        <h2 className="sec-title__title">Une application et un extranet pour la gestion</h2>
                        <p className="sec-title__text">
                            Nous avons développé une application reliée à l'extranet, afin que toutes les parties
                            puissent suivre (client), gérer (exploitant) et exécuter les prestations de lavage
                            (opérateur).
                        </p>
                    </div>
                    <div className="row">

                        <div className="col-xl-12">
                            <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/schema.png"}
                                   className="image-fit schema-img"/>
                        </div>
                    </div>
                </div>

                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                       alt="logo poubelle" className="poubelle-filigrane"/>
            </section>
            {/*<BlogOne/>*/}
            <FooterEugene/>
        </>
    )
}

export default EugeneColonnePage;
