import React from 'react';
import {Link} from 'react-router-dom';
import {Image} from "react-bootstrap";
import CONSTANTS from "../../constants";

const lienPresse1 = "https://www.francebleu.fr/infos/economie-social/dijon-les-eugene-arrivent-pour-desinfecter-les-poubelles-contenant-des-bio-dechets-8250610";
const lienPresse2 = "https://www.gazettebourgogne.fr/article/les-eugene-revolutionne-le-nettoyage-des-poubelles";
const lienPresse3 = "https://www.bienpublic.com/economie/2023/05/22/les-eugene-les-nettoyeurs-qui-partent-a-la-conquete-des-bacs-a-biodechets";
export default class RevuePresse extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="services-one-sec services-one-sec--services">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Revue de presse</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title">Ils parlent de nous</h2>
                        </div>

                        <div className="row">
                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/leseugene/rpresse1.webp"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={lienPresse1} target={"_blank"}>France bleu</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>
                                                Dijon : "Les Eugène" arrivent pour désinfecter les poubelles contenant des bio déchets
                                            </p>

                                            <div className="services-one__single-btn">
                                                <Link to={lienPresse1} target={"_blank"} className="thm-btn" data-text="Lire">Lire</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/leseugene/rpresse2.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={lienPresse2} target={"_blank"}>Gazette Bourgogne</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>
                                                « Les Eugène » Révolutionne le nettoyage des poubelles
                                            </p>

                                            <div className="services-one__single-btn">
                                                <Link to={lienPresse2} target={"_blank"} className="thm-btn" data-text="Lire">Lire</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/leseugene/rpresse3.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={lienPresse3} target={"_blank"}>Bien public</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>
                                                Les Eugène : les nettoyeurs qui partent à la conquête des bacs à biodéchets
                                            </p>

                                            <div className="services-one__single-btn">
                                                <Link to={lienPresse3} target={"_blank"} className="thm-btn" data-text="Lire">Lire</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
