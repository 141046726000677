import React from 'react';
import {Link} from 'react-router-dom';
import {Image} from "react-bootstrap";
import CONSTANTS from "../../constants";

export default class FooterEugene extends React.Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <footer className="footer-one-sec">
                    <div className="footer-one__pattern"
                         style={{backgroundImage: 'url(' + publicUrl + 'assets/images/pattern/footer-v1-pattern.png)'}}></div>
                    <div className="footer-one__top">
                        <div className="footer-one__top-img"
                             style={{backgroundImage: 'url(' + publicUrl + 'assets/images/leseugene/mini-laveuse.png)'}}></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__top-inner">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp"
                                                 data-wow-delay="0.1s">
                                                <div className="footer-widget__column footer-widget__about">
                                                    <div className="footer-widget__about-logo">
                                                        <Link to={CONSTANTS.URL_HOME}><img
                                                            src={publicUrl + "assets/images/leseugene/logo_eugene_blanc.png"}
                                                            alt="Logo" className="footer-logo"/></Link>
                                                    </div>

                                                    <p className="footer-widget__about-text">Les spécialistes de la
                                                        désinfection de
                                                        tous les types de contenants à déchets</p>

                                                    <div className="footer-widget__about-social-link">
                                                        <ul>
                                                            <li>
                                                                <a href="https://linkedin.com">
                                                                    <span className="first icon-linkedin"></span>
                                                                    <span className="second icon-linkedin"></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp"
                                                 data-wow-delay="0.3s">
                                                <div className="footer-widget__column footer-widget__services">
                                                    <h2 className="footer-widget__title">Nos Services</h2>
                                                    <ul className="footer-widget__services-list">
                                                        <li className="footer-widget__services-list-item">
                                                            <Link to={CONSTANTS.URL_BAC}>Bac roulants</Link>
                                                        </li>
                                                        <li className="footer-widget__services-list-item">
                                                            <Link to={CONSTANTS.URL_COLONNE}>Colonnes</Link>
                                                        </li>
                                                        <li className="footer-widget__services-list-item">
                                                            <Link to={CONSTANTS.URL_RESEAU}>Le réseau Les Eugène</Link>
                                                        </li>
                                                        <li className="footer-widget__services-list-item">
                                                            <Link to={CONSTANTS.URL_PRIVACY}>Politique de confidentialité</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp">
                                                {/*     data-wow-delay="0.5s">*/}
                                                {/*    <div className="footer-widget__column footer-widget__explore">*/}
                                                {/*        <h2 className="footer-widget__title">Our Explore</h2>*/}
                                                {/*        <ul className="footer-widget__explore-list">*/}
                                                {/*            <li className="footer-widget__explore-list-item"><Link to="/">Project*/}
                                                {/*                Planing</Link></li>*/}
                                                {/*            <li className="footer-widget__explore-list-item"><Link to="/">Cost*/}
                                                {/*                Calculator</Link></li>*/}
                                                {/*            <li className="footer-widget__explore-list-item"><Link to="/">Our*/}
                                                {/*                Testinonials</Link></li>*/}
                                                {/*            <li className="footer-widget__explore-list-item"><Link to="/">Mining*/}
                                                {/*                Sector</Link></li>*/}
                                                {/*            <li className="footer-widget__explore-list-item"><Link to="/">Closure*/}
                                                {/*                & Reform</Link></li>*/}
                                                {/*        </ul>*/}
                                                {/*    </div>*/}
                                            </div>

                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp"
                                                 data-wow-delay="0.7s">
                                                <Image
                                                    src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                                                    className="footer-poubelle"/>
                                                {/*<div className="footer-widget__column footer-widget__posts">*/}
                                                {/*    <h2 className="footer-widget__title">Featured Posts</h2>*/}
                                                {/*    <div className="footer-widget__posts-list">*/}
                                                {/*        <ul>*/}
                                                {/*            <li>*/}
                                                {/*                <div className="img-box">*/}
                                                {/*                    <img*/}
                                                {/*                        src={publicUrl + "assets/images/resources/footer-v1-img1.jpg"}*/}
                                                {/*                        alt=""/>*/}
                                                {/*                    <div className="overlay-icon">*/}
                                                {/*                        <Link*/}
                                                {/*                            to={process.env.PUBLIC_URL + `/blog-details`}><span*/}
                                                {/*                            className="icon-link"></span></Link>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}

                                                {/*                <div className="text-box">*/}
                                                {/*                    <p><Link*/}
                                                {/*                        to={process.env.PUBLIC_URL + `/blog-details`}>Althought*/}
                                                {/*                        Many People May <br/> Overlook The Need</Link>*/}
                                                {/*                    </p>*/}
                                                {/*                    <span>12 june 2022</span>*/}
                                                {/*                </div>*/}
                                                {/*            </li>*/}

                                                {/*            <li>*/}
                                                {/*                <div className="img-box">*/}
                                                {/*                    <img*/}
                                                {/*                        src={publicUrl + "assets/images/resources/footer-v1-img2.jpg"}*/}
                                                {/*                        alt=""/>*/}
                                                {/*                    <div className="overlay-icon">*/}
                                                {/*                        <Link*/}
                                                {/*                            to={process.env.PUBLIC_URL + `/blog-details`}><span*/}
                                                {/*                            className="icon-link"></span></Link>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}

                                                {/*                <div className="text-box">*/}
                                                {/*                    <p><Link*/}
                                                {/*                        to={process.env.PUBLIC_URL + `/blog-details`}>Veterans*/}
                                                {/*                        In Business Network <br/> National*/}
                                                {/*                        Conference</Link></p>*/}
                                                {/*                    <span>12 june 2022</span>*/}
                                                {/*                </div>*/}
                                                {/*            </li>*/}
                                                {/*        </ul>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-one__bottom clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__bottom-inner">
                                        <div className="footer-one__bottom-text">
                                            <p>Copyright &copy; {new Date().getFullYear()} tous droits réservés <Link
                                                to="/">Les Eugène</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}
