let BASE_URL = process.env.PUBLIC_URL + "/";

const CONSTANTS = {
    URL_HOME: BASE_URL,
    URL_BAC: BASE_URL + "lavage-bacs",
    URL_COLONNE: BASE_URL + "lavage-colonnes",
    URL_RESEAU: BASE_URL + "le-reseau-les-eugene",
    URL_PRIVACY: BASE_URL + "privacy",

}

export default CONSTANTS;
