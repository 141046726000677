import React from "react";
import Header from "../common/header/Header";
import FooterEugene from "../common/footer/FooterEugene";
import {Image} from "react-bootstrap";

const EugeneNetworkPage = () => {
    return (
        <>
            <Header/>

            <section className="mt-5 mb-5">
                <div className="container">
                    <div className="page-header__title">
                        <h1>Le réseau Les Eugène</h1>
                    </div>
                    <div className="page-header__subtitle italic">
                        <h4>Des partenaires nationaux</h4>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="testimonials-two-content__single">
                                <div className="testimonials-two-content__single-inner">
                                    <div className="icon-box">
                                        <span className="fa fa-quote-left"></span>
                                    </div>
                                    <div className="content-box">
                                        <h4 className="network-quote">Créer, développer des solutions et des services
                                            innovants, durables et
                                            respectueux
                                            des écosystèmes pour traiter tous les types de pollutions urbaines sans en
                                            générer de
                                            nouvelles</h4>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-5">
                                Telle est notre raison d'être. Basé sur le territoire du Grand Dijon et grâce à ses
                                partenaires dans toute la France, Les Eugène a développé un processus unique de
                                désinfection liant technologie, innovation et méthodes écologiques de lavage pour tous
                                les types de contenants.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 d-flex justify-content-center">

                    </div>
                </div>

            </section>

            <section className="work-perfomance-one-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="work-perfomance-one__content">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Le préfet Eugène Poubelle</h2>
                                    <p className="sec-title__text">
                                        Pourquoi les Eugène ? Eugène Poubelle est le préfet de Paris qui en 1883 a
                                        résolu le problème de l'enlèvement de déchets dans les rues en créant un
                                        contenant pour les déposer et les ramasser facilement.
                                    </p>
                                    <p className="mt-2">
                                        Sans Eugène Poubelle et son intervention, nous n'autions pas de contenants à
                                        laver aujourd'hui. C'est pourquoi nous voulons développer des petits Eugène,
                                        partout sur le territoire français, qui seront chargés de désinfecter tous les
                                        types de contenants à déchet de façon innovante et écoresponsable.
                                    </p>
                                </div>
                                <div>
                                    <div className="text-box">
                                        <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/vagues.png"}
                                               className={"image-fit"}/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-5">
                            <div className="work-perfomance-one__img">
                                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/eugene.jpeg"}
                                       className="image-fit"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                       alt="logo poubelle" className="poubelle-filigrane"/>
            </section>

            <section className="team-one-sec">
                <div className="shape1 float-bob-y">
                    <Image src={process.env.PUBLIC_URL + "/assets/images/shapes/team-v1-shape1.png"} alt=""/></div>
                <div className="container">
                    <div className="sec-title text-center">
                        <div className="sec-title__tagline">
                            <span className="left"></span>
                            <h6>Notre équipe</h6> <span className="right"></span>
                        </div>
                        <h2 className="sec-title__title">Des membres expérimentés</h2>
                        <p>
                            Notre équipe au siège est composée de 12 personnes, qui, fort de leur expérience ont acquis
                            les techniques de désinfection les plus optimales et qui transmettent ce savoir-faire
                            spécifiques à tous nos partenaires.
                        </p>
                    </div>
                    <div className="row">
                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.1s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/leseugene/BMacle.png"} alt=""/>
                                    <div className="content-box">
                                        <h2>
                                            Béatrice Macle
                                        </h2>
                                        <span>Administration</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}

                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.3s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/DESERTOT.png"}
                                           alt=""/>
                                    <div className="content-box">
                                        <h2>
                                            François-Xavier Desertot
                                        </h2>
                                        <span>Dirigeant & Fondateur</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}

                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.5s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/CBrauen.png"}
                                           alt=""/>
                                    <div className="content-box">
                                        <h2>
                                            Clarisse Brauen
                                        </h2>
                                        <span>Marketing & Communication</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}
                    </div>
                    <div className="row d-flex justify-content-evenly">
                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.5s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/OAhadi.png"}
                                           alt=""/>
                                    <div className="content-box">
                                        <h2>
                                            Oualid Ahadi
                                        </h2>
                                        <span>Responsable opérationnel et qualité <br /> Lavage Colonnes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}

                        {/* Start Team One Single */}
                        <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.5s">
                            <div className="team-one__single">
                                <div className="team-one__single-img">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/AAhadi.png"}
                                           alt=""/>
                                    <div className="content-box">
                                        <h2>
                                           Aziz Ahadi
                                        </h2>
                                        <span>Responsable opérationnel et qualité <br />Lavage Bacs</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Team One Single */}
                    </div>
                </div>
            </section>

            <section className="work-perfomance-one-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="work-perfomance-one__content">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Un parc de laveuse</h2>
                                    <p className="sec-title__text">
                                        8 laveuses de bacs et 2 laveuses de colonnes composent le parc des laveuses du
                                        réseau Les Eugène.
                                    </p>
                                    <p className="mt-2">
                                        6 autres laveuses vont bientôt compléter le parc pour répondre à toutes vos
                                        demandes.
                                    </p>
                                </div>
                                <div>
                                    <div className="text-box">
                                        <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/vagues.png"}
                                               className={"image-fit"}/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-5">
                            <div className="work-perfomance-one__img">
                                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/laveuses.png"}
                                       className="image-fit"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                       alt="logo poubelle" className="poubelle-filigrane"/>
            </section>

            {/*<BlogOne/>*/}
            <FooterEugene/>
        </>
    )
}

export default EugeneNetworkPage;
