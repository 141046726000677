import React from "react";
import Header from "../common/header/Header";
import FooterEugene from "../common/footer/FooterEugene";
import {Image} from "react-bootstrap";

const EugeneHome = () => {
    return (
        <>
            <Header/>

            <section className="page hero-container">
                <div className="container text-center">
                    <p className="hero-text">
                        La page demandée n'existe pas
                    </p>
                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/404.png"} className="hero-logo"/>

                </div>
            </section>
            
            <FooterEugene/>
        </>
    )
}

export default EugeneHome;
