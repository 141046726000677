import React, {useState} from 'react';
import * as AiIcons from 'react-icons/ai';
import {AiOutlineBars} from 'react-icons/ai';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import CONSTANTS from "../../constants";
import {Button} from "react-bootstrap";

const menuData = [
    {
        title: 'Les Eugene',
        path: CONSTANTS.URL_HOME,
    }, {
        title: 'Bacs roulants',
        path: CONSTANTS.URL_BAC,
    }, {
        title: 'Colonnes',
        path: CONSTANTS.URL_COLONNE,
    }, {
        title: 'Le réseau',
        path: CONSTANTS.URL_RESEAU,
    },
];

const SidebarLink = styled(Link)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    background: #76bd00;
    color: #fff;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  color: #fff;
  font-weight: 400;
`;

const DropdownLink = styled(Link)`
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 16px;

  &:hover,
  &:active {
    background: #76bd00;
    cursor: pointer;
    color: #fff;
  }
`;

const SubMenu = ({item}) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    return (
        <>
            <SidebarLink to={`${item.path}`} onClick={item.subNav && showSubnav}>
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
                </div>
            </SidebarLink>
            {subnav &&
                item.subNav.map((data, index) => (
                    <DropdownLink to={`${data.path}`} key={index}>
                        {item.icon}
                        <SidebarLabel>{data.title}</SidebarLabel>
                    </DropdownLink>
                ))}
        </>
    );
};

const NavIcon = styled(Link)`
  color: #001659;
  font-size: 2rem;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const SidebarNav = styled.nav`
  background-color: #001659;
  width: 300px;
  height: 100%;
  position: fixed;
  scroll-behavior: smooth;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  top: 0;
  left: ${({sidebar}) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 99999;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const NavMobileEugene = ({phoneNumber, phoneNumberLink}) => {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);
    let publicUrl = process.env.PUBLIC_URL + '/'

    return (
        <>
            <>
                <NavIcon to="#" style={{justifyContent: 'flex-end'}}>
                    <AiOutlineBars onClick={showSidebar}/>
                </NavIcon>

                <SidebarNav sidebar={sidebar} className="mobile-nav">
                    <SidebarWrap>
                        <div className="mobile-nav__content">
                            <div className="logo-box">
                                <Link to={CONSTANTS.URL_HOME} aria-label="logo image"><img
                                    src={publicUrl + "assets/images/leseugene/logo_eugene_blanc.png"} width="155"
                                    alt=""/></Link>
                            </div>
                            <NavIcon to="#">
                                <AiIcons.AiOutlineClose
                                    style={{
                                        color: 'white',
                                        fontSize: '18px',
                                        justifyContent: 'flex-start',
                                    }}
                                    onClick={showSidebar}
                                />
                            </NavIcon>
                            {menuData.map((item, index) => (
                                <SubMenu item={item} key={index}/>
                            ))}
                            <ul className="mobile-nav__contact list-unstyled">
                                <li>
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:contact@les-eugene.fr">contact @ les-eugene.fr</a>
                                </li>
                                <li>
                                    <i className="fa fa-phone-alt" aria-hidden="true"></i>
                                    <a href={phoneNumberLink} className={"phone-here"}>
                                        {phoneNumber}
                                    </a>
                                </li>
                            </ul>
                            <div className="mobile-nav__top">
                                <div className="mobile-nav__social">
                                    <a href="https://twitter.com/">Découvrez-nous sur LinkedIn <span
                                        className="fab fa-linkedin"></span></a>
                                </div>
                            </div>
                            <Button className="button-extranet button-green" onClick={() => {
                                window.open("https://extranet.les-eugene.fr", "_blank")
                            }}>Extranet</Button>
                        </div>
                    </SidebarWrap>
                </SidebarNav>
            </>
        </>
    );
};

export default NavMobileEugene;
