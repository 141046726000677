import React from 'react';
import {NavLink} from "react-router-dom";
import CONSTANTS from "../../constants";

const NavEugene = () => {
    return (
        <div className="main-menu text-center">
            <nav>
                <ul className="main-menu__list">
                    <li>
                        <NavLink exact={"true"} to={CONSTANTS.URL_HOME} activeClassName="active">Les Eugene</NavLink>
                    </li>
                    <li>
                        <NavLink to={CONSTANTS.URL_BAC} activeClassName="active">Bacs roulants</NavLink>
                    </li>
                    <li>
                        <NavLink to={CONSTANTS.URL_COLONNE} activeClassName="active">Colonnes</NavLink>
                    </li>
                    <li>
                        <NavLink to={CONSTANTS.URL_RESEAU} activeClassName="active">Le réseau</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default NavEugene;


