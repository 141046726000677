import React from 'react';
import LogoBlack from './LogoBlack';
import {Button, Image, OverlayTrigger, Tooltip} from "react-bootstrap";
import NavEugene from "./NavEugene";
import NavMobileEugene from "./NavMobileEugene";
import {NavLink} from "react-router-dom";

function Header() {
    const phoneNumber = "03-80-78-07-38"
    const phoneNumberLink = "tel:" + phoneNumber
    const renderTooltip = (props) => (
        <Tooltip className="button-tooltip" {...props}>
            Bientôt !
        </Tooltip>
    );

    return (
        <>
            <header className="main-header main-header-three clearfix">
                <div className="main-header-three__wrapper">

                    <div className="main-header-three__top clearfix">
                        <div className="main-header-three__top-inner">
                            <div className="left">
                                <NavLink to={process.env.PUBLIC_URL}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/leseugene/logo_eugene_blanc.png"}
                                         alt="Logo Eugene" className={"logo-eugene"}/>
                                </NavLink>
                            </div>

                            <div className="right">
                                <div className="title">
                                    <p>Découvrez-nous sur LinkedIn</p>
                                </div>

                                <ul className="social-links">
                                    <li><a href="https://www.linkedin.com/company/les-eugene/" target="_blank"
                                           rel="noreferrer"><span
                                        className="icon-linkedin"></span></a></li>
                                </ul>

                                <div className="social-links">
                                    <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/poubelle.png"}
                                           alt="Logo poubelle" className={"logo-poubelle"}/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="main-header-three__middle">
                        <div className="container">
                            <div className="main-header-three__middle-inner">
                                <div className="contact-info">
                                    <ul>
                                        <li>
                                            <div className="icon-box">
                                                <span className="icon-pin"></span>
                                            </div>
                                            <div className="text-box">
                                                <h3>7A Rue du Bailly</h3>
                                                <p>21000 Dijon</p>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="icon-box">
                                                <span className="icon-email"></span>
                                            </div>
                                            <div className="text-box">
                                                <h3>Contactez-nous</h3>
                                                <p className="email"><a
                                                    href="mailto:contact@les-eugene.fr">contact @ les-eugene.fr</a>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon-box">
                                                <span className="icon-phone-call"></span>
                                            </div>
                                            <div className="text-box">
                                                <h3>Contactez-nous</h3>
                                                <p className="phone">
                                                    <a href={phoneNumberLink} className={"phone-here"}>
                                                        {phoneNumber}
                                                    </a>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="right">
                                    <Button className="button-extranet" onClick={() => {
                                        window.open("https://extranet.les-eugene.fr", "_blank")
                                    }}>Extranet</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-header-three__bottom">
                        <div className="container">
                            <div className="main-header-three__bottom-inner">
                                <div className="main-header-one__bottom-left">
                                    <nav className="main-menu main-menu--1">
                                        <div className="main-menu__inner">
                                            <NavMobileEugene phoneNumber={phoneNumber}
                                                             phoneNumberLink={phoneNumberLink}/>
                                            <div className="stricky-one-logo">
                                                <LogoBlack/>
                                            </div>
                                            <NavEugene/>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </header>

            <header className="mobile-main-header">
                <div className="mobile-main-header-three__top clearfix">
                    <div className="main-header-three__top-inner">
                        <div className="left">
                            <NavLink to={process.env.PUBLIC_URL}>
                                <Image src={process.env.PUBLIC_URL + "/assets/images/leseugene/logo_eugene_blanc.png"}
                                       alt="Logo Eugene" className={"logo-eugene"}/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </header>


            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>


        </>
    )
}

export default Header;
